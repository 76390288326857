import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  MainPageContainer,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import {
  usePersonalInformation,
  UserProfileDetails,
  EditProfilePhoto,
  EditDisplayName,
} from '@scfhp-web/shared-module';
import { UserDataModel } from '@scfhp-web/shared-module/src/common/types';

export const PersonalInformationPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'STR_PERSONAL_INFO_PAGE' }));

  const { data, isLoading } = usePersonalInformation();

  const [displayName, setDisplayName] = useState<string>(
    data?.userProfile?.preferredFirstName || data?.userProfile?.firstName || '',
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'STR_PERSONAL_INFO' })}
        goBack={history.goBack}
      />
      <StackLayout horizontalAlignment="center">
        <Box
          css={{
            width: '100%',
            maxWidth: 370,
          }}
        >
          <EditProfilePhoto />
          <EditDisplayName
            displayName={displayName}
            setDisplayName={setDisplayName}
          />
          <UserProfileDetails
            data={
              {
                ...data?.initialValues,
                preferredFirstName: displayName,
              } as UserDataModel
            }
          />
        </Box>
      </StackLayout>
    </MainPageContainer>
  );
};
