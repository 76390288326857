import { useHistory } from 'react-router-dom';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useCommonPageSetup } from '@scfhp-web/shared-module';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { CheckConfirmation } from 'components/check-confirmation/check-confirmation.component';
import FlexibleCardLayout from 'components/cards/flexible-card-layout.component';
import { useCommunicationPreferences } from 'hooks/use-communication-preferences.hook';
import { EmailDetails } from 'components/communication-preferences/email-details.component';
import { EmailDisclaimer } from 'components/communication-preferences/email-disclaimer.component';

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' });
  const checkBoxText = formatMessage({
    id: 'STR_COMMUNICATION_PREFERENCES_ALLOW_EMAIL',
  });

  useCommonPageSetup({
    title,
    details: 'user views the communication preferences screen ',
  });

  const history = useHistory();
  const [
    { allowEmailNotification, isLoading, isWaiting, email },
    { setEmailNotification },
  ] = useCommunicationPreferences();

  return (
    <MainPageContainer width="100%">
      <HeroSection title={title} goBack={history.goBack} />
      <FlexibleCardLayout>
        <CheckConfirmation
          label={checkBoxText}
          name="allowEmailNotification"
          checked={allowEmailNotification}
          handleCheck={setEmailNotification}
          loading={isLoading || isWaiting === 'email'}
          ariaDisabled={!!isWaiting}
        />
        {allowEmailNotification && <EmailDetails email={email || ''} />}
        <EmailDisclaimer />
      </FlexibleCardLayout>
    </MainPageContainer>
  );
};
