import React, { useEffect } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useAuth } from '@leagueplatform/auth-utils';
import { identify } from '@leagueplatform/analytics';
import { useHistory } from 'react-router-dom';
import { usePersonalInformation } from '@scfhp-web/shared-module';
import { useUserEligibility } from 'hooks/use-user-eligibility.hook';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { NotEligibleScreen } from 'components/error/not-eligible-screen.component';
// import { buildErrorUrlParams } from 'utils/auth-error-helpers';
// import { AUTH0_ERROR_TYPES } from 'common/constants';
// import { StandaloneAuth } from '@leagueplatform/auth-standalone';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  // const { isAuthenticated, error } = useAuth({
  //   redirectToLogInWhenSignedOut: true,
  // });
  const { isAuthenticated, error } = useAuth();
  const { data } = usePersonalInformation();

  const { eligible, loading } = useUserEligibility(isAuthenticated);

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    if (error || isAuthenticated === false) {
      history.push(`/sign-out`);
    }
  }, [error, isAuthenticated, history]);

  useEffect(() => {
    if (isAuthenticated && data.userId) {
      identify({
        id: data.userId as string,
        employer_group_id: '',
        tenant_id: 'scfhp',
      });
    }
  }, [isAuthenticated, data]);

  if (!isAuthenticated || loading) return <LoadingIndicator />;

  // Handle eligibility errors or ineligibility
  if (eligible === false) {
    return <NotEligibleScreen errorType={ErrorTypes.NOT_ELIGIBLE} />;
  }

  return children;
};
