import { PRODUCT_AREA } from '@leagueplatform/analytics';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { getMasonryDriver } from '@leagueplatform/masonry';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { useHistory } from 'react-router-dom';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useCommonPageSetup } from '@scfhp-web/shared-module';
import { HeroSection } from 'components/hero-section/hero-section.component';

const MasonryDriver = getMasonryDriver({ rootNodeId: 'authorizations$root' });

export const AuthorizationPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const title = formatMessage({ id: 'STR_AUTHORIZATIONS' });

  useCommonPageSetup({ title, productArea: PRODUCT_AREA.GET_CARE });
  return (
    <MainPageContainer
      width="100%"
      css={{
        '.GDS-vertical-stack-layout': {
          width: '100%',
        },
      }}
    >
      <HeroSection title={title} goBack={history.goBack} height="154px" />

      <StackLayout
        orientation="vertical"
        css={{
          maxWidth: '894px',
        }}
      >
        <MasonryEngine driver={MasonryDriver} />
      </StackLayout>
    </MainPageContainer>
  );
};
