import { Box } from '@leagueplatform/genesis-core';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { NavLink } from 'react-router-dom';

import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';

export const defaultMobileNavLinkStyles = {
  '&&': {
    display: 'flex',
    alignItems: 'center',
    color: '$onSurfaceTextSubdued',
    typography: '$buttonTwo',
    borderRadius: '$medium',
    padding: '$half',
    '&[aria-current="page"], &.active': {
      color: '$interactiveActionPrimary',
      borderBottomColor: '$interactiveActionPrimary',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '$interactiveActionHovered',
    },
  },
};

type MobileNavLinkProps = {
  children: React.ReactNode;
  linkData: any;
  onLinkClick: () => void;
};

export const MobileNavLink = ({
  children,
  linkData,
  onLinkClick,
}: MobileNavLinkProps) => {
  const { text, color } = linkData;

  if ('to' in linkData) {
    const { to } = linkData;

    if ('target' in linkData && isAbsoluteUrl(linkData?.to)) {
      // Nav item is an external link
      const { target } = linkData;

      return (
        <Box
          as="a"
          href={to}
          target={target}
          rel="noopener noreferrer"
          css={{
            ...defaultMobileNavLinkStyles,
            ...(color && { color: `${color} !important` }),
          }}
          onClick={onLinkClick}
        >
          {children}
          {target === '_blank' && <OpensInANewTabMessage />}
        </Box>
      );
    }
    // Nav item is an internal link
    return (
      <Box
        as={NavLink}
        to={to}
        id={`mobile-nav-link-${text}`}
        css={{
          ...defaultMobileNavLinkStyles,
          ...(color && { color: `${color} !important` }),
        }}
        onClick={onLinkClick}
      >
        {children}
      </Box>
    );
  }

  // Nav item is a button link
  if ('onClick' in linkData)
    return (
      <Box
        as="li"
        css={{
          ...defaultMobileNavLinkStyles,
          ...(color && { color: `${color} !important` }),
        }}
        onClick={onLinkClick}
      >
        {children}
      </Box>
    );

  return null;
};
