import { useEffect, useState, useCallback } from 'react';
import { usePersonalInformation } from '@scfhp-web/shared-module';
import { captureAppError } from '@leagueplatform/observability';
import { useHistory } from 'react-router-dom';
import { useIntl } from '@leagueplatform/locales';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { US_STATES } from 'commons/constants';
import { useSubmitAnalyticsEvent } from 'hooks/use-set-analytics.hook';
import { parseClinicAddress } from 'utils/parse-clinic-address';
import { formatPhoneNumber } from 'utils/format-phone-number';
import { useProviderRedirect } from './use-pcp-provider-redirect.hook';
import { useMailingAddress } from './use-mailing-address.hook';
import { useModal } from './use-modal.hook';

export const pcpFormModel = [
  {
    id: 'line1',
    labelId: 'STR_ADDRESS_LINE1',
    defaultMessage: 'Address Line 1',
    type: 'text',
    required: true,
    size: 50,
  },
  {
    id: 'line2',
    labelId: 'STR_ADDRESS_LINE2',
    defaultMessage: 'Address Line 2 (Optional)',
    type: 'text',
    size: 50,
  },
  {
    id: 'city',
    labelId: 'STR_CITY',
    defaultMessage: 'City',
    type: 'text',
    required: true,
    size: 30,
  },
  {
    id: 'state',
    labelId: 'STR_STATE',
    defaultMessage: 'State',
    type: 'select',
    options: US_STATES,
    required: true,
    size: 30,
  },
  {
    id: 'zip',
    labelId: 'STR_ZIP',
    defaultMessage: 'Zipcode',
    type: 'text',
    required: true,
    size: 20,
    validation: (value: string) => /^\d{5}(-\d{4})?$/.test(value),
  },
];

export const useChangePCP = () => {
  const { extractPCPDetails } = useProviderRedirect();
  const history = useHistory();

  const { data: personalInformation, isLoading } = usePersonalInformation();
  const { isShowing: isModalOpen, toggle: toggleModal } = useModal();
  const { mutateAsync: submitAnalyticsEvent } = useSubmitAnalyticsEvent();
  const { formatMessage } = useIntl();

  const [pcps, setPcps] = useState([
    {
      title: formatMessage({
        id: 'STR_CURRENT_PCP_INFO',
      }),
      pcpName: '',
      clinicName: '',
      clinicAddress: '',
      phoneNumber: '',
    },
    {
      title: formatMessage({
        id: 'STR_NEW_PCP_INFO',
      }),
      pcpName: '',
      clinicName: '',
      clinicAddress: '',
      phoneNumber: '',
    },
  ]);

  const [step, setStep] = useState(1);
  const mailingAddress = useMailingAddress();

  const isFinalStep = step === 3;

  const stableExtractPCPDetails = useCallback(extractPCPDetails, [
    extractPCPDetails,
  ]);

  useEffect(() => {
    if (isLoading || !personalInformation) return;

    const customFields = personalInformation?.customFields || [];
    const pcpData = JSON.parse(
      customFields.find((field: any) => field.name === 'pcpData')?.value ||
        '{}',
    );

    const currentPcpDetails = {
      title: formatMessage({
        id: 'STR_CURRENT_PCP_INFO',
      }),
      pcpName: pcpData.pcpName || '',
      clinicName: pcpData.pcpLocation || '',
      clinicAddress: pcpData.pcpClinicAddress || '',
      phoneNumber: pcpData.pcpPhone || '',
    };

    const newPcpDetailsFromQuery = stableExtractPCPDetails();
    const newPcpDetails = {
      title: formatMessage({
        id: 'STR_NEW_PCP_INFO',
      }),
      pcpName: newPcpDetailsFromQuery.ProviderName || '',
      clinicName: newPcpDetailsFromQuery.ClinicName || '',
      clinicAddress: decodeURIComponent(
        newPcpDetailsFromQuery.ClinicAddress || '',
      ),
      phoneNumber: newPcpDetailsFromQuery.Phone || '',
    };

    const newPcps = [currentPcpDetails, newPcpDetails];
    setPcps((prevPcps) => {
      const isEqual = JSON.stringify(prevPcps) === JSON.stringify(newPcps);
      return isEqual ? prevPcps : newPcps;
    });

    const mailingLocation = personalInformation?.userProfile?.locations?.find(
      (location) => location.type === 'service',
    );

    if (mailingLocation) {
      const formattedAddress = {
        line1: mailingLocation.address1 || '',
        line2: mailingLocation.address2 || '',
        city: mailingLocation.city || '',
        state: mailingLocation.state || '',
        zip: mailingLocation.postal || '',
      };

      mailingAddress.setCurrentAddress(
        `${formattedAddress.line1}, ${
          formattedAddress.line2 ? `${formattedAddress.line2}, ` : ''
        }${formattedAddress.city}, ${formattedAddress.state} ${
          formattedAddress.zip
        }`,
      );

      mailingAddress.setCurrentPhoneNumber(
        formatPhoneNumber(personalInformation?.phoneNumber || ''),
      );
    }
  }, [
    isLoading,
    personalInformation,
    mailingAddress,
    formatMessage,
    stableExtractPCPDetails,
  ]);

  const validateForm = (address: Record<string, string>) => {
    const errors: Record<string, string> = {};
    pcpFormModel.forEach((field: any) => {
      if (
        field.required &&
        field.validation &&
        !field.validation(address[field.id] || '')
      ) {
        errors[field.id] = formatMessage({
          id: `STR_${field.id}_INVALID`,
          defaultMessage: `${field.defaultMessage} is invalid.`,
        });
      }
    });
    mailingAddress.setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleBack = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.GET_CARE,
      sub_product_area: 'Review change PCP',
      screen_name: 'Review change PCP',
      detail: 'go back button',
    });
    if (step === 1) {
      history.push('/get-care');
    } else {
      setStep((prev) => Math.max(1, prev - 1));
    }
  };

  const handleSubmit = async () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.GET_CARE,
      sub_product_area: 'Review change PCP',
      screen_name: 'Review change PCP',
      detail: 'submit pcp change',
    });
    const addressDetails = parseClinicAddress(pcps[1].clinicAddress);
    const currentAddress = personalInformation?.userProfile?.locations?.find(
      (location) => location.type === 'service',
    );

    const mailingAddressDetails = !mailingAddress.isSameAddress
      ? {
          address_line_1: mailingAddress.newAddress.line1,
          address_line_2: mailingAddress.newAddress.line2 || null,
          city: mailingAddress.newAddress.city,
          state: mailingAddress.newAddress.state,
          zip: mailingAddress.newAddress.zip,
        }
      : {
          address_line_1: currentAddress?.address1 || '',
          address_line_2: currentAddress?.address2 || '',
          city: currentAddress?.city || '',
          state: currentAddress?.state || '',
          zip: currentAddress?.postal || '',
        };

    const params = {
      event_id: crypto.randomUUID(),
      event_type: 'user_change_pcp_set',
      entity_type: 'user',
      entity_id: null,
      user_id: personalInformation?.userId || '',
      tenant_id: 'scfhp',
      connection_id: null,
      event_timestamp: new Date().toISOString(),
      info: {
        pcp_name: pcps[1]?.pcpName,
        clinic_address_line_1: addressDetails.line1,
        clinic_address_line_2: addressDetails.line2 || null,
        clinic_address_city: addressDetails.city,
        clinic_address_state: addressDetails.state,
        clinic_address_zip: addressDetails.zip,
        clinic_address_country: 'US',
        clinic_phone_number: pcps[1]?.phoneNumber || '',
        user_mailing_address: mailingAddressDetails,
      },
    };

    const payload = {
      name: 'user_change_pcp_set',
      params: {
        ...params,
        platform: 'web',
        userAgent: window.navigator.userAgent,
      },
      echo: true,
    };

    try {
      await submitAnalyticsEvent(payload);
      toggleModal();
    } catch (error) {
      if (error instanceof Error) {
        captureAppError(error, {
          ownershipTeam: 'SENTRY_CONSTANTS.OWNERSHIP_TEAM',
          errorName: 'Failed to submit analytics event',
        });
      }
    }
  };

  const handleNext = async () => {
    if (step === 2) {
      if (mailingAddress.isSameAddress) {
        await handleSubmit();
      } else {
        const isValid = validateForm(mailingAddress.newAddress);
        if (isValid) {
          setStep(3);
        }
      }
    } else if (step === 3) {
      await handleSubmit();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const addressData = [
    {
      id: 'current',
      titleId: 'STR_CURRENT_ADDRESS',
      titleDefault: 'Current Address',
      addressLabelId: 'STR_MAILING_ADDRESS',
      address: mailingAddress.currentAddress,
      showEdit: false,
    },
    {
      id: 'new',
      titleId: 'STR_NEW_ADDRESS',
      titleDefault: 'New Address',
      addressLabelId: 'STR_MAILING_ADDRESS',
      address: `${mailingAddress.newAddress.line1}, ${
        mailingAddress.newAddress.line2
          ? `${mailingAddress.newAddress.line2}, `
          : ''
      }${mailingAddress.newAddress.city}, ${mailingAddress.newAddress.state} ${
        mailingAddress.newAddress.zip
      }`,
      showEdit: true,
      onEdit: handleBack,
    },
  ];

  return {
    pcps,
    isLoading,
    isModalOpen,
    mailingAddress,
    toggleModal,
    step,
    handleNext,
    handleBack,
    isFinalStep,
    addressData,
  };
};
