import {
  StackLayout,
  HeadingText,
  ParagraphText,
  useMediaQuery,
  queryHelpers,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from 'react-router-dom';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import exitWarning from 'assets/pcp-modal.svg';
import { isMobileUserAgent } from 'utils/is-mobile-user-agent';
import { ModalActionButton } from './common/modal-action-button.component';

export const SubmitPCPModal = () => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const isMobileDevice = isMobileUserAgent();
  const history = useHistory();

  return (
    <BaseModalContainer
      css={{
        ' .GDS-modal': {
          width: '100vw !important',
          height: '100vh !important',
          maxWidth: '100vw !important',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ' .GDS-heading-bar-right-action': {
          position: 'fixed !important',
          right: '5% !important',
        },
      }}
      handleClose={() => history.push('/get-care')}
      showDismissButton={!isMobileDevice}
    >
      <StackLayout
        css={{
          maxWidth: '583px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageMessage
          image={exitWarning}
          css={{
            flexGrow: 1,
            textAlign: 'left',
            '@mobile': { textAlign: 'center' },
          }}
        >
          <HeadingText
            level="2"
            size={{
              '@initial': 'lg',
              '@mobile': 'xl',
            }}
            css={{
              paddingBlock: '$one',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {formatMessage({ id: 'STR_PCP_MODAL_TITLE' })}
          </HeadingText>
          <ParagraphText css={{ textAlign: 'center' }}>
            {formatMessage({ id: 'STR_PCP_MODAL_BODY' })}
          </ParagraphText>
          {!isMobileDevice && (
            <StackLayout
              horizontalAlignment="center"
              orientation={{
                '@initial': 'horizontal',
                '@mobile': 'vertical',
              }}
              spacing="$one"
              css={{ flexGrow: 0, marginTop: '$two' }}
            >
              <ModalActionButton
                actionType="cancel"
                labelId="STR_GO_HOME"
                isMobile={isMobile}
                onClick={() => history.push('/')}
                cssStyle={{
                  color: '$secondaryTextDefault',
                  borderRadius: '62px',
                  borderColor: '$primaryBackground',
                }}
              />
              <ModalActionButton
                actionType="submit"
                onClick={() => history.push('/get-care')}
                labelId="STR_GO_TO_CARE_PAGE"
                isMobile={isMobile}
                isDestructive
                cssStyle={{
                  borderRadius: '62px',
                  background: '$primaryBackgroundDefault',
                }}
              />
            </StackLayout>
          )}
        </ImageMessage>
      </StackLayout>
    </BaseModalContainer>
  );
};
