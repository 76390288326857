import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useSyncRouteWithBrowser = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const syncRouteWithBrowser = () => {
      const browserPath = window.location.pathname;

      if (location.pathname !== browserPath) {
        history.push(browserPath);
      }

      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }, 0);
    };

    syncRouteWithBrowser();

    window.addEventListener('popstate', syncRouteWithBrowser);

    return () => {
      window.removeEventListener('popstate', syncRouteWithBrowser);
    };
  }, [location, history]);
};

export default useSyncRouteWithBrowser;
